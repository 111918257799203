<template>
  <div
    v-if="haveBanner"
    class="common-modals"
  >
    <auth-modal v-if="authModalState" />
    <lead-modal v-if="leadModalState" />
    <black-friday-modal v-if="blackFridayModalState" />
    <new-year-modal v-if="newYearModalState" />
    <january-modal v-if="januaryModalState" />
    <video-modal v-if="videoModalState" />
    <download-soft-skills-modal v-if="softDownloadModalState" />
    <open-doors-modal v-if="openDoorsState" />
    <nabor24-modal v-if="nabor24Modal" />
    <summer24-modal v-if="anapa24Modal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AuthModal from '~/components/v2/auth/AuthModal.vue';
import LeadModal from './LeadModal.vue';
import VideoModal from './VideoModal.vue';
import DownloadSoftSkillsModal from './DownloadSoftSkillsModal.vue';
import BlackFridayModal from './BlackFridayModal.vue';
import NewYearModal from './NewYearModal.vue';
import JanuaryModal from './JanuaryModal.vue';
import OpenDoorsModal from './OpenDoorsModal.vue';
import Nabor24Modal from './Nabor24Modal.vue';
import Summer24Modal from './Summer24Modal.vue';

export default {
  name: 'CommonModals',
  components: {
    Summer24Modal,
    Nabor24Modal,
    JanuaryModal,
    NewYearModal,
    BlackFridayModal,
    DownloadSoftSkillsModal,
    VideoModal,
    LeadModal,
    AuthModal,
    OpenDoorsModal,
  },

  computed: {
    ...mapGetters('modals', {
      authModalState: 'authModal',
      leadModalState: 'leadModal',
      softDownloadModalState: 'softDownloadModal',
      blackFridayModalState: 'blackFridayModal',
      newYearModalState: 'newYearModal',
      januaryModalState: 'januaryModal',
      videoModalState: 'videoModal',
      openDoorsState: 'openDoorsModal',
      nabor24Modal: 'nabor24Modal',
      anapa24Modal: 'anapa24Modal',
    }),

    /**
     * в зависимости от, наличии модального компонента в некоторых местах меняются стили
     * пример: .common-modals ~ .header
     * @returns boolean
     */
    haveBanner() {
      return this.authModalState
        || this.leadModalState
        || this.videoModalState
        || this.softDownloadModalState
        || this.blackFridayModalState
        || this.newYearModalState
        || this.januaryModalState
        || this.openDoorsState
        || this.nabor24Modal
        || this.anapa24Modal;
    },
  },

  mounted() {
    window.addEventListener('keydown', this.keydownHnd);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownHnd);
  },

  methods: {
    ...mapActions('modals', {
      closeAllModals: 'closeAll',
    }),

    keydownHnd(event) {
      if (event.code === 'Escape') {
        this.closeAllModals();
      }
    },
  },
};
</script>
