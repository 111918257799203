<template>
  <div class="download-soft-skills-modal">
    <div class="download-soft-skills-modal__inner">
      <button
        class="download-soft-skills-modal__close m-btn"
        @click="closeSoftDownloadModal"
      >
        <img
          src="/v2/modal/close.svg"
          alt="cross"
          class="lead__close-icon"
        >
      </button>

      <span>Готово!</span>
      <h3>Спасибо, что заполнили форму, скачивание скоро начнется</h3>
      <img
        src="/v2/modal/yellow-check.png"
        alt="check"
        class="download-soft-skills-modal__img"
      >
      <p>
        Если скачивание не началось, то можете скачать .pdf файл
        <a
          href="/v2/docs/soft-skills/soft-skills-program.pdf"
          download
        >
          по этой ссылке
        </a>
      </p>
    </div>

    <div
      class="download-soft-skills-modal__overlay"
      @click="closeSoftDownloadModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DownloadSoftSkillsModal',

  computed: {
    ...mapGetters({
      isTablet: 'isTablet',
    }),
  },

  mounted() {
    window.open('/v2/docs/soft-skills/soft-skills-program.pdf', '_blank');

    if (this.isTablet) {
      document.body.style.overflow = 'hidden';
    }
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },

  methods: {
    ...mapActions('modals', {
      closeSoftDownloadModal: 'closeSoftDownload',
    }),
  },
};
</script>

<style scoped lang="scss">

.download-soft-skills-modal {
  &__inner {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 38px 43px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto;

    width: min(610px, calc(100% - 16px));
    height: fit-content;
    max-height: 95vh;

    border-radius: 20px;
    border: 2px solid $black;
    background: $white-color;
    box-shadow: 4px 4px 0 0 $black;

    overflow: hidden;

    @include media-down(tablet) {
      padding-top: 16px;
      padding-bottom: 40px;
      box-shadow: 2px 4px 0 0 $black;
    }

    @include media-down(mobile) {
      overflow: hidden;
    }
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  &__overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2.5px);
  }

  span {
    color: #89C053;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 130%;
  }

  h3 {
    color: $black;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;
  }

  .download-soft-skills-modal__img {
    display: block;
    margin: 40px auto;
  }

  p {
    max-width: 385px;

    color: #242424;

    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;

    a {
      color: #4A88DA;

      &:hover {
        border-bottom: 2px solid #4A88DA;
      }
    }
  }
}

</style>
