<template>
  <div
    class="ny-banner"
    @click="openModal(MODAL_TYPES.newYearBottomBanner)"
  >
    <div class="ny-banner__content">
      <h5 class="ny-banner__title">
        начните новый год с выгоды! Праздничные скидки на все
      </h5>
      <span class="ny-banner__cheap">до 60%</span>

      <button
        class="ny-banner__btn m-btn"
        @click="openModal(MODAL_TYPES.newYearBottomBanner)"
      >
        забронировать
      </button>

      <div class="ny-banner__countdown">
        <p class="ny-banner__countdown--text">
          время и количество мест ограничено
        </p>

        <div class="ny-banner__countdown--items">
          <div
            v-for="(item, index) of countdown"
            :key="index"
            class="ny-banner__countdown--item"
          >
            <p>
              {{ item.value }}
            </p>
            <span>
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import getRemainingTime from '@/utils/remainingTime';
import { MODAL_TYPES } from '../common/AppHeader/data';

export default {
  name: 'NewYear25Banner',
  data: () => ({
    remainingTime: {},
    timer: null,
    MODAL_TYPES,
  }),
  computed: {
    targetDate() {
      const today = new Date();

      const oddDate = Boolean(today.getDate() % 2);

      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + (oddDate ? 1 : 2));
      tomorrow.setHours(0, 0, 0, 0);

      return tomorrow;
    },
    countdown() {
      return [
        {
          value: this.remainingTime.days || '00',
          text: this.getDeclension(this.remainingTime.days, ['День', 'Дня', 'Дней']),
        },
        {
          value: this.remainingTime.hours || '00',
          text: this.getDeclension(this.remainingTime.hours, ['Час', 'Часа', 'Часов']),
        },
        {
          value: this.remainingTime.minutes || '00',
          text: this.getDeclension(this.remainingTime.minutes, ['Минута', 'Минуты', 'Минут']),
        },
        {
          value: this.remainingTime.seconds || '00',
          text: this.getDeclension(this.remainingTime.seconds, ['Секунда', 'Секунды', 'Секунд']),
        },
      ];
    },
  },
  beforeMount() {
    this.remainingTime = getRemainingTime(this.targetDate);

    this.timer = setInterval(() => {
      this.remainingTime = getRemainingTime(this.targetDate);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),
    getDeclension(number, words) {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.ny-banner {
  display: block;
  position: relative;
  height: calc(var(--scale) * 60);
  background-image: url("/v2/new-year/2025/banner/desktop.webp");
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @include media-down($size-tablet) {
    background-image: url("/v2/new-year/2025/banner/tablet.webp");
    height: calc(var(--scale) * 50);
  }

  @include media-down($size-mobile) {
    background-image: url("/v2/new-year/2025/banner/mobile.webp");
  }

  * {
    font-family: 'Onest', sans-serif;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
  }

  &__title {
    max-width: calc(var(--scale) * 453);
    position: relative;
    z-index: 2;

    font-weight: 700;
    font-size: calc(var(--scale) * 28);
    line-height: 90%;
    letter-spacing: -0.02em;
    text-transform: lowercase;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-bottom: calc(var(--scale) * 5);
      max-width: calc(var(--scale) * 188);
      font-size: calc(var(--scale) * 14);
    }

    @include media-down($size-mobile) {
      margin-bottom: 0;
      margin-left: calc(-1 * var(--scale) * 10);
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      width: calc(var(--scale) * 196);
      height: calc(var(--scale) * 26);
      right: calc(var(--scale) * 74);
      bottom: calc(-1 * var(--scale) * 2);
      background-color: #ED131C;

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 96);
        height: calc(var(--scale) * 15);
        left: 0;
      }
    }
  }

  &__cheap {
    padding: calc(var(--scale) * 2) calc(var(--scale) * 6);
    position: absolute;
    left: calc(var(--scale) * 766);
    bottom: calc(var(--scale) * 3);
    background-color: #22AE9D;
    border-radius: calc(var(--scale) * 33);
    transform: rotate(-7deg);

    font-weight: 700;
    font-size: calc(var(--scale) * 15);
    line-height: 130%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      bottom: 0;
      left: calc(var(--scale) * 203);
      transform: rotate(4deg);
    }

    @include media-down($size-mobile) {
      bottom: calc(-1 * var(--scale) * 3);
      left: calc(var(--scale) * 105);
    }
  }

  &__btn {
    margin-left: calc(var(--scale) * 30);
    width: calc(var(--scale) * 254);
    height: calc(var(--scale) * 48);
    background-color: #ED131C;
    border-radius: calc(var(--scale) * 16);

    font-weight: 700;
    font-size: calc(var(--scale) * 20);
    line-height: 120%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-left: calc(var(--scale) * 19);
      width: calc(var(--scale) * 136);
      height: calc(var(--scale) * 28);
      border-radius: calc(var(--scale) * 8);
      font-size: calc(var(--scale) * 11);
    }

    @include media-down($size-mobile) {
      display: none;
    }
  }

  &__countdown {
    margin-left: calc(var(--scale) * 152);

    @include media-down($size-tablet) {
      margin-left: calc(var(--scale) * 31);
    }

    @include media-down($size-mobile) {
      margin-left: calc(-1 * var(--scale) * 8);
    }

    &--text {
      margin-bottom: calc(var(--scale) * 2);
      position: relative;
      font-weight: 500;
      font-size: calc(var(--scale) * 13);
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      text-align: center;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 10);
      }

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: calc(var(--scale) * 1);
        right: calc(var(--scale) * 13);
        width: calc(var(--scale) * 73);
        height: calc(var(--scale) * 13);
        background-color: #ED131C;

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 55);
          height: calc(var(--scale) * 10);
          right: 0;
        }
      }
    }

    &--items {
      display: flex;
    }

    &--item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(var(--scale) * 64);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 44);
      }

      &:not(&:last-of-type):after {
        content: '';
        display: block;
        width: calc(var(--scale) * 1);
        height: calc(var(--scale) * 20);
        position: absolute;
        top: calc(50% - calc(var(--scale) * 10));
        right: calc(-1 * var(--scale) * 0.5);
        background-color: rgba(255, 255, 255, 0.5);

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 15);
        }
      }

      p {
        margin-block: calc(-1 * var(--scale) * 2);
        font-variant-numeric: tabular-nums;
        font-weight: 600;
        font-size: calc(var(--scale) * 28);
        line-height: 110%;
        letter-spacing: -0.08em;
        text-transform: uppercase;
        color: #FFFFFF;

        @include media-down($size-tablet) {
          margin-block: calc(-1 * var(--scale) * 1);
          font-size: calc(var(--scale) * 22);
        }
      }

      span {
        font-weight: 500;
        font-size: calc(var(--scale) * 10);
        line-height: 110%;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.5);

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 8);
        }
      }
    }
  }
}
</style>
